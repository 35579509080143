import React from 'react'
import { Container, Row } from 'react-bootstrap';

const Contact = () => {
    return (

        <Container className='mh100 '>
            <Row>
            <h3 className='mt-4'>Контакты</h3>
            <h6 className='mt-4'>Для заказов: </h6>
            <a href="mailto:prezent72.rf@list.ru">prezent72.rf@list.ru</a>


            <h6 className='mt-4'>Оформление заказа на сайте и уточнения деталей платежа, наличие цветов, тарифы доставки по телефону: </h6>
            

    
            <p><a href="tel:89123939150">8 (912) 393-91-50</a> (WhatsApp, Viber)</p>
    
            <div className="boxes  flex-column flex-md-row">
                  
            <label className={"checked"} style={{minHeight: 0, width: "100%", marginBottom: '10px'} }>
                      <a href="https://api.whatsapp.com/send?phone=+79123939150&text=Здравствуйте! " target="_blank">
                        <div className="title">WhatsApp</div>
                      </a>
                    </label>
                  
                 
                    <label className={"checked"} style={{minHeight: 0, width: "100%", marginRight: '15.98px' , marginBottom: '10px'} }>
                      <a href="https://vk.com/butikprezent" target="_blank">
                        <div className="title">ВКонтакте</div>
                      </a>
                    </label>
                  
                 
                    <label className={"checked"} style={{minHeight: 0, width: "100%", marginBottom: '10px'} }>
                      <a href="https://t.me/butikprezent" target="_blank">
                        <div className="title">Telegram</div>
                      </a>
                    </label>
               
              </div>
    
    <h6 className='mt-4'>Наш основной салон:</h6>
    
    <p>Работает салон в круглосуточном режиме, без выходных и перерывов.</p>
    
    <p>г. Тобольск, 10 микрорайон, д.71А, офисная вставка, 1 этаж.</p>
    
    
    <p>Заказы принимаются по телефонам:  <a href="tel:89123939150">8 (912) 393-91-50</a> (WhatsApp, Viber) ежедневно с 6:00 до 21:00 по московскому времени.</p>
    
    <p>Если Вы оформили заказ в интернет-магазине в ночное время, то менеджер с Вами свяжется в рабочее время. Если заказ очень срочный, свяжитесь с основным салоном по телефонам:  <a href="tel:8(3456)27-99-17">27-99-17</a> .</p>
    
    <p>Наше местное время + 2 часа от московского времени.</p>
    <p></p>
    <p>ИП Закутянский Святослав Васильевич, ИНН 720611080178, ОГРНИП 321723200021971, 8-982-904-55-03</p>
    <p>Юридический адрес: 626150, 626150, Тюменская обл., город Тобольск, мкр. 6, дом № 110 корпус 1, кв. 114</p>
    <p>Почтовый адрес: 626150, Тюменская обл., город Тобольск, мкр. 6, дом № 110 корпус 1, кв. 114</p>
            </Row>


        </Container>

    );

}

export default Contact;