import React, {useContext} from 'react';
import { observer } from "mobx-react-lite"
import { Button, Nav } from "react-bootstrap"
import { Context } from '..';
import { useParams, useNavigate } from 'react-router-dom';
import { CATEGORY_ROUTE } from '../utils/consts';


const TypeButton = observer(({setExp, horisontal}) => {
    const {product} = useContext(Context)
    const params = useParams();
    const navigate = useNavigate ()

    const handleOnClick = (i)=>{
        //console.log(i , product.selectedType )
       // console.log(i +'!=='+ product.selectedType + '    ' + i !== product.selectedType)
        if(i.toString()  !== product.selectedType.toString() ){
            product.setProducts([])
            //product.setSelectedType(i)
            navigate(CATEGORY_ROUTE + '' + i)
        }
        
        
    }

    
    if (horisontal){
        let countWords = 0
        return(
            <Nav className="">
                {product.types.map((type, i) => { 
                    countWords += type.name.length  
                    if((i + 1)*16 + countWords*9.3 < 1096)  
                        return( <Nav.Link key={type.id} onClick={   ()=> { handleOnClick(i)}   } style={{whiteSpace: 'nowrap' }} variant={params.slug == type.id ? "pink" : "outline pink"}>{type.name}</Nav.Link>)   
                })}
    
            </Nav>
        )
    }
    return(
        <Nav className="justify-content-end flex-grow-1 pe-3">
            {product.types.map((type, i) => {    
                   return( <Nav.Link key={type.id} onClick={   ()=> {setExp();  handleOnClick( i)}   } style={{whiteSpace: 'nowrap' }} variant={params.slug == type.id ? "pink" : "outline pink"}>{type.name}</Nav.Link>)   
            })}
 
        </Nav>
    )
})
export default TypeButton