import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '..';
import moneyImg from '../assets/img/money.png'
import { fetchItems } from '../http/item';

const Product = observer(() => {
    const { cart, product } = useContext(Context)
    const params = useParams()
    const t = product.getType(params.slug)
    const d = product.getProduct(params.productid)
    const selectedType = product.selectedType
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)

        if(d == null)
        fetchItems(0).then(data => {
            let i = 0;
            let idCat = 0;
            data['categories'].forEach(element => {
              if(element.id == params.slug) idCat = i;
              i++
            });
            product.setSelectedType(idCat)
            //product.setTypes(data['categories'])
            
            //product.setProducts(data['items'])
          })


      }, [])

    if (d === null || t === null)
        return <Container className='mt-4'>
        <Row>Загрузка...</Row></Container>


    const zoom = (e) => {
        var zoomer = e.target;
       

        let offsetX
        let offsetY

        e.nativeEvent.offsetX ? offsetX = e.nativeEvent.offsetX : offsetX = e.nativeEvent.touches[0].pageX
        e.nativeEvent.offsetY ? offsetY = e.nativeEvent.offsetY : offsetX = e.nativeEvent.touches[0].pageX


        const x = offsetX/zoomer.offsetWidth*100
        const y = offsetY/zoomer.offsetHeight*100

        document.querySelector(".zoom").style.backgroundPosition = x + '% ' + y + '%';
      }

    return (


        <Container className='mt-4'>
            <Row>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" onClick={() => navigate('/' + selectedType)} style={{ cursor: 'pointer', }}><p>{t.name}</p></li>
                        <li className="breadcrumb-item active" aria-current="page">{d.name}</li>
                    </ol>
                </nav>

            </Row>
            <Row>
                <Col md={6}>
                <figure className="zoom" onMouseMove={(e) => zoom(e)} style={{backgroundImage: `url(${d.img}?t=${Math.round(new Date().getTime()/(1000*60*5))})`}}>
                <Image width='100%' src={`${d.img}?t=${Math.round(new Date().getTime()/(1000*60*5))}`} />
</figure>
                    
                    </Col>
                <Col md={6}>
                    <h3 className='my-3'>{d.name}</h3>
                    <p hidden>Наличие: {d.address}</p>

                    <div>
                        
                        <Image  height='12px'  src={moneyImg} /> и наличные</div>

                    <h5><b>Цена: {d.price}  ₽</b></h5>

                    <div className="mb-3" dangerouslySetInnerHTML={{ __html: d.desc.replace('\n', '<br/>') }} />
                    <div
                        onClick={() => {
                            if (cart.checkID(d.id) !== 0)
                                navigate('/cart')
                            else if (d.count > cart.checkID(d.id)){
                                cart.setProduct(d.id, { photo: d.img, price: d.price, name: d.name, maxCount: d.count, address: d.address })

                                product.setShowModal(d.category)
                                product.setShowModalOt(d.category)
                            }
                        }}
                        style={{ cursor: 'pointer', }} className={`d-flex flex-row align-items-center justify-content-around py-2 mx-3 text-white ${cart.checkID(d.id) == 0 ? 'bg-black' : 'bg-green'}`}>

                        <div >
                            {cart.checkID(d.id) == 0 ? 'В корзину' : 'Оформить'}
                        </div>

                    </div>
                </Col>

            </Row>

        </Container>

    );



})

export default Product;