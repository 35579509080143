import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { Context } from '..';
import CartProduct from '../components/CartProduct';
import VideoUploader from '../components/VideoUploader';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { getDateTime, inPoly } from '../utils/functions';
import { checkProduct, checkPromo, fetchCard, fetchDeliveryCost, sendOrder } from '../http/item';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';






const Cart = observer(() => {
  const { cart, product } = useContext(Context)
  const [value, setValue] = React.useState(null);
  const [timeVar, setTimeVar] = useState([])
  const [isHoliday, setIsHoliday] = useState(false)
  const [showToast, setShowToast] = useState(false);

  const [disableAdress, setDisableAdress] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const oneC_a = queryParameters.get("a")
  const oneC_c = queryParameters.get("c")
  const oneC_p = queryParameters.get("p")
  const oneC_t = queryParameters.get("t")

  const oneC_order = queryParameters.get("id")

  const [modalShow, setModalShow] = React.useState(false);
  let sum = 0;


  
  const [trShow, SettrShow] = useState(false);


  const [productCheckShow, productCheckSetShow] = useState(false);

  const [productCheckText, setProductCheckText] = useState('');
  const handleProductCheckClose = () => productCheckSetShow(false);

  const handletrClose = () => SettrShow(false);
  


  
  const [promo, setPromo] = useState('')


  const [deliveryType, setDeliveryType] = useState()
  const [paymentType, setPaymentType] = useState('ONLINE')
  const [recipient, setRecipient] = useState('')

  const [deliverySum, setDeliverySum] = useState({ price: 0, pricePr: 0 })



  const [recipientOtherName, setRecipientOtherName] = useState('')
  const [recipientOtherPhone, setRecipientOtherPhone] = useState('+7 ')

  const [recipientMail, setRecipientMail] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [recipientValidAddress, setRecipientValidAddress] = useState(false)

  useEffect(() => {

    // Address
    if(typeof cart.address === 'string' && cart.address.length > 10 ){
      handlerAddressChange(JSON.parse(cart.address))
      setRecipientValidAddress(true)
    }

    if(oneC_order !== null){


      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch("https://xn--72-mlcaj1bkiw.xn--p1ai/api/api.php?method=getParamForCart&id="+oneC_order, requestOptions)
        .then(response => response.text())
        .then(result => {
            
            const data = JSON.parse(result)  
              
            // phone
            if(typeof data.phone !== 'undefined'){
                const e =  {
                  target: {
                    value: data.phone       
                  }
                }
              handlerPhoneChange(e)
            }


            // Получатель
            if(data.p == 1){
              setRecipient('OTHER');
            }else if(data.p == 0){
              setRecipient('ME');
            }

            // Address
            if(data.address !== null){
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Token d0e36ca0b51a064d08a29971e27342ab86317574");
              myHeaders.append("Content-Type", "application/json");
        
              var raw = JSON.stringify({
                "query": data.address,
                "count": 1
              });
        
              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };
        
              fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", requestOptions)
                .then(response => response.text())
                .then(result => {

                  handlerAddressChange(JSON.parse(result).suggestions[0])
                  setRecipientValidAddress(true)

                })
                .catch(error => console.log('error', error));
        
            } 


            // Товары
            if(typeof data.products !== 'undefined'){
              cart.clearCart()
              data.products.map(el => cart.setProduct1C(el.name,el.id,el.count,el.price,el.sklad,el.maxCount))
            }
        })
        .catch(error => console.log('error', error));
    }

  }, [])


  useEffect(() => {

    checkIsHolyday(value)

  }, [deliveryType, disableAdress])

  const [hiddenSam, setHiddenSam] = useState(true)
  const checkIsHolyday = (currD) => {

    fetchDeliveryCost().then(data => {
      let a = 0;
      let period = 1;
      (data.data).forEach(el => {
        //console.log(el)
        if (Date.parse(el["Начало"]) <= Date.parse(currD) && Date.parse(el["Окончание"]) >= Date.parse(currD)) {
          a = el["Стоимость"]
          period = el["Период"]
        }
      })


      //console.log(a)
      setIsHoliday(a > 0)
      setHiddenSam(!(a > 0) && false)
      if (!(a > 0)) {

        //setDeliveryType('DELIVERY')
      }


      // Рассчет стоимости доставки
      if (typeof recipientAddress !== 'undefined' && recipientAddress !== '' && deliveryType == 'DELIVERY') {

        const find = inPoly(recipientAddress.data.geo_lat, recipientAddress.data.geo_lon, recipientAddress.value)

        //console.log(recipientAddress)

        if (find.name === 'Город' && a > 0) {

          setDeliverySum({ price: 0, pricePr: a })
        } else {
          if (sum < 1000) {
            setDeliverySum({ price: 150, pricePr: a })
          } else {
            setDeliverySum({ price: 0, pricePr: a })
          }
        }


        if (find.name !== 'Город') {
          setDeliverySum({ price: find.price, pricePr: find.pricePr })
        }

        if(disableAdress){
          if (sum < 1000) {
            setDeliverySum({ price: 150, pricePr: a })
          }else{
            setDeliverySum({ price: 0, pricePr: a })
          }
          setRecipientValidAddress( true)
        }else{
          setRecipientValidAddress( (find.find && recipientAddress.value.length > 35) )
        }

        


      }else if(typeof recipientAddress !== 'undefined' && deliveryType === 'SAM'){
        setDeliverySum({ price: 0, pricePr: 0 })
      }


      const val = new Date()
      //const period = a > 0 ? 3 : 1

      let min = (currD.getDate() === new Date().getDate()) ? getDateTime(new Date(val).setMilliseconds(3600000)).getHours() : 0

      let arr = []
      while (min < 24) {

        if (min + period > 24)
          arr.push(`${(min.toString()).padStart(2, "0")}:00 - ${((min + period - 24).toString()).padStart(2, "0")}:00`)
        else
          arr.push(`${(min.toString()).padStart(2, "0")}:00 - ${((min + period).toString()).padStart(2, "0")}:00`)
        min += period
      }

      setTimeVar(arr)
      document.querySelector('select').value = 'Время'
    })
  }

  const setDateDelivery = (val) => {
    setValue(val)
    checkIsHolyday(val)

  }
  const isShowOtText = (cart) => {
    let isShow = false
    Object.keys(cart.products).forEach(item => {
      if (cart.products[item].data.name.indexOf('открытка') !== -1) isShow = true


    })
    return isShow
  }
  const [promoSum, setPromoSum] = useState(0)
  const handlerSetPromo = e => {
    setPromo(e.target.value)
    checkPromo(e.target.value).then(data => {
      if (data.data !== 0 && typeof data.data.d1 !== 'undefined') {
        setPromoSum(parseInt(data.data.value))

      } else {
        setPromoSum(0)
      }

    })
  }


  

  const checkProd = async () => {
    let strItems = []
    Object.keys(cart.products).forEach(element => {
      strItems.push({ "id": cart.products[element].id, "count": cart.products[element].count })
    });

    const data = await checkProduct(strItems)

    productCheckSetShow((data.data.length > 0))
    setProductCheckText(data.data.slice(0, -2))

    return !(data.data.length > 0)


  }

  useEffect(() => {
    let strItems = []
    Object.keys(cart.products).forEach(element => {
      strItems.push(cart.products[element].id)
    });
    if (value === null)
      setDateDelivery(new Date())

    setInterval(() => checkIsHolyday(new Date()), 1000 * 60 * 30)


    checkProd()


    if(localStorage.getItem('promocode') !== null )
      setPromo(localStorage.getItem('promocode'))
      checkPromo(localStorage.getItem('promocode')).then(data => {
      if (data.data !== 0 && typeof data.data.d1 !== 'undefined') {
            setPromoSum(parseInt(data.data.value))
    
          } else {
            setPromoSum(0)
          }
      })

    const tel = cart.recipientPhone.replace(/[^+\d]/g, '').replace(/^.{2}/, '')
    if (tel.length === 10)
      fetchCard('8' + tel).then(
        data => {

          cart.setDiscount(data.data === 0 ? 0 : data.data.replace(/[^+\d]/g, ''))

        }

      )



  }, [])



  useEffect(() => {
    checkIsHolyday(value == null ? new Date() : value)
  }, [value, recipientAddress])


  const handlerAddressChange = e => {
    /*fetchHoliday().then(data => {
      setIsHoliday(data.data)
    })*/
  console.log(e)
    setRecipientAddress(e)

    checkIsHolyday(value == null ? new Date() : value)
    cart.setAddress(JSON.stringify(e) )

    /*if (typeof e !== 'undefined') {

      const find = inPoly(e.data.geo_lat, e.data.geo_lon)

      if (sum < 1000 && find.price < 150)
        setDeliverySum({ price: 150, pricePr: 150 })
      else
        setDeliverySum({ price: find.price, pricePr: find.pricePr })

      setRecipientValidAddress(find.find && e.value.length > 35)
    }*/
  }
  const handlerSamFNameChange = e => {
    cart.setSamFName(e.target.value)
  }
  const handlerNameChange = e => {
    cart.setRecipientName(e.target.value)
  }
  const handlerOtherNameChange = e => {
    setRecipientOtherName(e.target.value)
  }
  const handlerMailChange = e => {
    setRecipientMail(e.target.value)
  }
  const handlerOtherPhoneChange = (event) => {

    const value = event.target.value.replace(/\D+/g, "");
    const numberLength = 11;

    let result;
    if (event.target.value.includes("+8") || event.target.value[0] === "8") {
      result = "+";
      alert(result)
    } else {
      result = "+";
    }

    //
    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 0:
          result += prefixNumber(value[i]);
          continue;
        case 4:
          result += ") ";
          break;
        case 7:
          result += "-";
          break;
        case 9:
          result += "-";
          break;
        default:
          break;
      }
      result += value[i];
    }
    if (result.length < 2) { result = '+7' }
    setRecipientOtherPhone(result)

  };


  const handleOnOrder = async () => {
    setModalShow(true)
    const productInSklad = await checkProd()

    if (!productInSklad) {
      setModalShow(false)
      return false

    }

    if (!document.getElementById('prop_CONF').checked) {
      setModalShow(false)
      setShowToast(true)
      return false
    }





    else {
      let _cart = []
      let _sum = 0

      Object.keys(cart.products).forEach(key => {
        _sum += cart.products[key].count * cart.products[key].data.price
        _cart.push({ id: cart.products[key].id, name: cart.products[key].data.name, img: cart.products[key].data.photo, count: cart.products[key].count, price: cart.products[key].data.price, address: cart.products[key].data.address })

      })

      /*cart.products.map(item => {
        const count = cart.checkID(item.id)
        if (count > 0) {
          _sum += cart.checkID(item.id) * item.price
          _cart.push({ id: item.id, name: item.name, img: item.img, count: count, price: item.price })
        }
      })*/

      /* Вадидируем данные */
      let valid = false
      if (deliveryType === 'SAM')
        valid = valid || (cart.recipientName.length > 1 && cart.SamFName.length > 1 && cart.recipientPhone.length === 18 && cart.SamFName.length > 1)

      if (deliveryType === 'DELIVERY') {
        if (recipient === 'ME')
          valid = valid || (cart.recipientName.length > 1 && cart.recipientPhone.length === 18 && recipientValidAddress)
        if (recipient === 'OTHER')
          valid = valid || (cart.recipientName.length > 1 && cart.recipientPhone.length === 18 && recipientValidAddress && recipientOtherName.length > 1 && recipientOtherPhone.length === 18)
      }
      if (document.querySelector('select').value == 'Время') {
        alert("Не заполнено время доставки")
        setModalShow(false)
        return
      }
      if (!valid) {
        alert("Не заполнены обязательные поля")
        setModalShow(false)
      } else
        if (paymentType === 'ONLINE') {

          setModalShow(true)
          SettrShow(false)
          sendOrder({
            products: _cart,
            sum: _sum,
            deliverySum: deliverySum,
            deliveryType: deliveryType,
            paymentType: paymentType,
            recipient: recipient,
            recipientName: cart.recipientName + " " + cart.SamFName,
            recipientPhone: cart.recipientPhone,
            recipientMail: recipientMail,
            samFName: cart.SamFName,
            recipientAddress: recipientAddress.value,
            recipientOtherName: recipientOtherName,
            recipientOtherPhone: recipientOtherPhone,
            date: value,
            time: document.querySelector('select').value,
            disableAdress: disableAdress,
            discount: (cart.discount > promoSum ? cart.discount : promoSum),
            promo: (cart.discount > promoSum ? 'Д/К' : 'Промокод: ' + promo),
            isHoliday: isHoliday,
            comment: document.querySelector('textarea').value,
            otText: document.querySelectorAll('textarea')[1] === undefined ? '' : document.querySelectorAll('textarea')[1].value,
            video: cart.video === "" ? "" : cart.video.data
          }).then((data) => {
            cart.setOrderId(data.data.id)
            document.location.href = data.data.url

            // document.location.href = data
            product.setOtText('')
          })
        }
    }
  }

  const handlerPhoneChange = (event) => {

    const value = event.target.value.replace(/\D+/g, "");
    const numberLength = 11;

    let result;
    if (event.target.value.includes("+8") || event.target.value[0] === "8") {
      result = "+";
    } else {
      result = "+";
    }

    //
    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 0:
          result += prefixNumber(value[i]);
          continue;
        case 4:
          result += ") ";
          break;
        case 7:
          result += "-";
          break;
        case 9:
          result += "-";
          break;
        default:
          break;
      }
      result += value[i];
    }
    const tel = result.replace(/[^+\d]/g, '').replace(/^.{2}/, '')

    if (tel.length === 10)
      fetchCard("8" + tel).then(
        data => {

          cart.setDiscount(data.data === 0 ? 0 : data.data.replace(/[^+\d]/g, ''))

        }

      )

    cart.setRecipientPhone(result)

  };
  const prefixNumber = (str) => {
    if (str === "7") {
      return "7 (";
    }
    if (str === "8") {
      return "7 (";
    }
    if (str === "9") {
      return "7 (9";
    }
    return "7 (";
  };

  // const [selectedDate, handleDateChange] = useState(new Date());


  if (cart.count === 0)
    return (<Container className='mh100 mt-4'>
      Ваша корзина пуста
    </Container>
    )
  return (
    <>
      <marquee behavior="scroll" direction="left" style={{ backgroundColor: '#ff408169', padding: '8px', color: 'white' }}>При активном VPN могут возникнуть проблемы в работе сайта</marquee>
      <Container className='mh100 mt-4'>

        <Row>
          <Col md={7} className='cart-col order-2 order-md-1'>
            <div className='cart-col br7 bg-white px-3 py-3 mx-1 my-1'>
              <h5>Выберите, как вы хотите получить заказ</h5>

              <div className="boxes">
                <label hidden={hiddenSam} className={deliveryType === 'SAM' ? "checked" : ''} onClick={() => setDeliveryType('SAM')}>

                  <div className="title">Самовывоз</div>
                  <span className="description">Я сам(а) заберу заказ</span>
                </label>
                <label className={deliveryType === 'DELIVERY' ? "checked" : ''} onClick={() => setDeliveryType('DELIVERY')}>

                  <div className="title">Доставка</div>
                  <span className="description">Заказ будет доставлен нашим курьером</span>
                </label>
              </div>



              <div hidden={deliveryType !== 'DELIVERY'}>
                <h5>Выберите, кто будет получать заказ</h5>

                <div className="boxes">
                  <label className={recipient === 'ME' ? "checked" : ''} onClick={() => setRecipient('ME')}>

                    <div className="title">Я сам(а)</div>
                    <span className="description">Заказ будет доставлен вам лично</span>
                  </label>
                  <label className={recipient === 'OTHER' ? "checked" : ''} onClick={() => setRecipient('OTHER')}>

                    <div className="title">Другой(ая)</div>
                    <span className="description">Заказ будет доставлен другому человеку</span>
                  </label>
                </div>
              </div>



              <div hidden={(deliveryType !== 'DELIVERY' || recipient !== 'OTHER')}>
                <h5>Получатель</h5>
                <div className="field-input-box">
                  <input type="text" className={`field-input ${recipientOtherName.length <= 1 ? 'error' : ''}`} required="" value={recipientOtherName} onChange={handlerOtherNameChange} aria-required="true" placeholder="Имя получателя" />
                </div>
                <div className="field-input-box  d-flex flex-column flex-md-row">
                  <input type="text" className={`field-input  ${recipientOtherPhone.length !== 18 ? 'error' : ''}`} required="" value={recipientOtherPhone} onChange={handlerOtherPhoneChange} aria-required="true" placeholder="Телефон" />
                </div>
                <AddressSuggestions token="d0e36ca0b51a064d08a29971e27342ab86317574" inputProps={{ className: recipientValidAddress ? 'react-dadata__input' : 'react-dadata__input field-input error' }} value={recipientAddress} onChange={e => handlerAddressChange(e)} defaultQuery={recipientValidAddress ? undefined : 'Тюменская обл, г Тобольск, '}  />
                <div className="field-checkbox mb-4" style={{padding: '5px', border: 'dashed 1px #ff4081'}}>
                  <input type="checkbox" id="prop_CONF2" className="checkbox" checked={disableAdress} onChange={ () => setDisableAdress(!disableAdress)}/>
                  <label for="prop_CONF2" >Не знаю адрес, уточните у получателя</label>
                </div>
                <div className="field-input-box">
                  <VideoUploader />
                </div>
              </div>




              <div hidden={deliveryType !== 'SAM' && (recipient === '')}>
                <h5>Ваши данные</h5>

                <div className="field-input-box">
                  <input type="text" className={`field-input ${cart.recipientName.length <= 1 ? 'error' : ''}`} required="" value={cart.recipientName} onChange={handlerNameChange} aria-required="true" placeholder="Ваше имя" />
                </div>


                <div className="field-input-box" hidden={!(deliveryType === 'SAM')}>
                  <input type="text" className={`field-input ${cart.SamFName.length <= 1 ? 'error' : ''}`} required="" value={cart.SamFName} onChange={handlerSamFNameChange} aria-required="true" placeholder="Ваша фамилия" />
                </div>

                <div className="field-input-box  d-flex flex-column flex-md-row">
                  <input type="text" className={`field-input me-md-3 ${cart.recipientPhone.length !== 18 ? 'error' : ''}`} required="" value={cart.recipientPhone} onChange={handlerPhoneChange} aria-required="true" placeholder="Телефон" />

                  <input type="text" className={`field-input `} required="" value={recipientMail} onChange={handlerMailChange} aria-required="true" placeholder="email" />

                </div>

                <div className="field-input-box" hidden={(deliveryType !== 'DELIVERY' || recipient !== 'ME')}>
                  <AddressSuggestions 
                          token="d0e36ca0b51a064d08a29971e27342ab86317574" 
                          inputProps={{ className: recipientValidAddress ? 'react-dadata__input' : 'react-dadata__input field-input error' }} 
                          value={recipientAddress} 
                          onChange={e => handlerAddressChange(e)} 
                          uid="one"
                          defaultQuery={recipientValidAddress ? undefined : 'Тюменская обл, г Тобольск, '}  
                  />
                
                  
                </div>
                <div className="field-checkbox mb-4" style={{padding: '5px', border: 'dashed 1px #ff4081'}} hidden={(deliveryType !== 'DELIVERY' || recipient !== 'ME')}>
                  <input type="checkbox" id="prop_CONF2" className="checkbox" checked={disableAdress} onChange={ () => setDisableAdress(!disableAdress)}/>
                  <label for="prop_CONF2" >Не знаю адрес, уточните у получателя</label>
                </div>

              </div>
              <div className={(((deliveryType === 'DELIVERY' && !(recipient === '')) || deliveryType === 'SAM')) ? "d-flex" : ''} hidden={!(((deliveryType === 'DELIVERY' && !(recipient === '')) || deliveryType === 'SAM'))}>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label={deliveryType === 'DELIVERY' ? "Дата доставки" : "Дата самовывоза"}
                  minDate={new Date()}
                  value={value}
                  className='w-100'
                  onChange={(newValue) => {
                    setDateDelivery(newValue);
                  }}
                />


                <div className="field-input-box" style={{ width: '50%' }}>
                  <select id="timepicker" className="field-input " style={{ width: '100%', height: '43px' }}>
                    <option>Время</option>
                    {timeVar.map(el => {
                      return <option>{el}</option>
                    })}

                  </select>
                </div>


              </div>
              <textarea hidden={!((deliveryType === 'DELIVERY' && (recipient === 'ME' || recipient === 'OTHER')) || deliveryType === 'SAM')} cols="30" rows="3" name="USER_COMMENT" className="field-input" placeholder="Комментарий"></textarea>



              {
                isShowOtText(cart) ? <textarea hidden={!((deliveryType === 'DELIVERY' && (recipient === 'ME' || recipient === 'OTHER')) || deliveryType === 'SAM')} value={product.otText} onChange={(el) => { product.setOtText(el.target.value) }} maxlength="150" cols="30" rows="3" name="USER_OTCOMMENT" className="field-input" placeholder="Тест открытки"></textarea> : <></>
              }






              <div hidden={!(((deliveryType === 'DELIVERY' && (recipient === 'ME' || recipient === 'OTHER')) || deliveryType === 'SAM'))}>
                <h5>Оплата</h5>

                <div className="boxes">
                  <label className={`${paymentType === 'NAL' ? "checked" : ''} d-none`} onClick={() => setPaymentType('NAL')}>

                    <div className="title">Наличными</div>
                    <span className="description">при получении</span>
                  </label>
                  <label className={paymentType === 'ONLINE' ? "checked" : ''} onClick={() => setPaymentType('ONLINE')}>

                    <div className="title">Картой</div>
                    <span className="description">оплатить заказ онлайн переводом</span>
                  </label>
                </div>

              </div>






              <div hidden={!((paymentType === 'NAL' || paymentType === 'ONLINE') && ((deliveryType === 'DELIVERY' && (recipient === 'ME' || recipient === 'OTHER')) || deliveryType === 'SAM'))} >

                <input type="text" className={`field-input `} required="" value={promo} onChange={handlerSetPromo} aria-required="true" placeholder="Промокод" />

                <div className="field-checkbox mb-4">
                  <input type="checkbox" id="prop_CONF" className="checkbox" checked />
                  <label for="prop_CONF">Даю согласие на обработку персональных данных</label>

                </div>

                <div className="description" style={{marginBottom: "10px"} }>Возникли трудности при оформлении заказа? Готовы помочь</div>
                <div className="boxes flex-column flex-md-row">
                  
                    <label className={"checked"} style={{minHeight: 0, width: "100%", marginBottom: '10px'} }>
                      <a href="https://api.whatsapp.com/send?phone=+79123939150&text=Здравствуйте! " target="_blank">
                        <div className="title">WhatsApp</div>
                      </a>
                    </label>
                  
                 
                    <label className={"checked"} style={{minHeight: 0, width: "100%", marginRight: '15.98px' , marginBottom: '10px'} }>
                      <a href="https://vk.com/butikprezent" target="_blank">
                        <div className="title">ВКонтакте</div>
                      </a>
                    </label>
                  
                 
                    <label className={"checked"} style={{minHeight: 0, width: "100%", marginBottom: '10px'} }>
                      <a href="https://t.me/butikprezent" target="_blank">
                        <div className="title">Telegram</div>
                      </a>
                    </label>
                 
                </div>



                <section className="">
                  <button className="cart-btn" onClick={() => handleOnOrder()}>Оформить заказ</button>
                </section>
              </div>
            </div>
          </Col>
          <Col md={5} className='cart-col order-1 order-md-2'>
            <div className='cart-col br7 bg-white  mx-1 my-1'>
              <Container >
                {Object.keys(cart.products).map(item => {

                  const count = cart.checkID(cart.products[item].id)
                  if (count > 0) {
                    sum += cart.checkID(cart.products[item].id) * cart.products[item].data.price
                    return <CartProduct id={cart.products[item].id} key={cart.products[item].id} name={cart.products[item].data.name} photo={cart.products[item].data.photo} count={count} maxCount={cart.products[item].data.maxCount} price={cart.products[item].data.price} address={cart.products[item].data.address} />
                  } else { return '' }
                })}


                <Row className='pb-2'>
                  <div className="py-3"><b>Ваш заказ</b></div>
                  <div className='d-flex justify-content-between'><div>Товары</div>  <h6>{sum} ₽</h6>  </div>
                  <div className='d-flex justify-content-between'><div>Доставка</div>  <h6>{isHoliday ? deliverySum.pricePr : deliverySum.price} ₽</h6>  </div>
                </Row>

                <Row className='pb-2'>
                  <Col><hr /> </Col>
                </Row>

                <Row className='pb-4'>
                  <div className={`d-flex justify-content-between ${cart.discount === 0 & promoSum === 0 ? 'd-none' : ''} `} ><div>Скидка</div>  <h6>{(sum - sum * (1 - 0.01 * (cart.discount > promoSum ? cart.discount : promoSum))).toFixed(2)} ₽</h6>  </div>

                  <div className='d-flex justify-content-between'><div>Итого</div>  <h6>{(sum * (1 - 0.01 * (cart.discount > promoSum ? cart.discount : promoSum)) + (isHoliday ? deliverySum.pricePr : deliverySum.price)).toFixed(2)} ₽</h6>  </div>
                </Row>

              </Container>
            </div>
          </Col>
        </Row>

        <div style={{ position: 'fixed', bottom: '15px', zIndex: 99999999, right: '10px' }} >
          <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg=''>

            <Toast.Body style={{ backgroundColor: '#ffe9f0' }}>Необходимо дать согласие на обработку персональных данных</Toast.Body>
          </Toast>
        </div>




        <div className={`d-flex align-items-center justify-content-center text-white ${modalShow ? '' : 'd-none'}`} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', background: 'rgb(0 0 0 / 75%)', zIndex: '999999' }}>
          <Spinner animation="border" variant="danger" />
          <div className='ms-3'>перенаправление на страницу оплаты...</div>
        </div>





        <Modal show={productCheckShow} onHide={handleProductCheckClose}>
          <Modal.Header closeButton>
            <Modal.Title>Дорогой гость! </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            Выбранный вами товар({productCheckText}) в корзине, куплен. <br /><br />
            Чтобы продолжить покупку. Удалите не актуальный товар из корзины и переходите на  онлайн витрину. <br /><br />
            Рекомендуем после выбора сразу оформлять заказ, а не откладывать в корзину на долгое время. <br /><br />
            Приятных покупок!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleProductCheckClose}>
              Понятно
            </Button>

          </Modal.Footer>
        </Modal>

        <Modal show={trShow} onHide={handletrClose}>
          <Modal.Header closeButton>
            <Modal.Title>Технические работы</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            
            Внимание! Ведутся технические работы! 
            <br /><br />
            Уважаемые клиенты! Для проведения оплаты вашего заказа свяжитесь с нами по телефону +7 (912) 393-91-50. 
            <br /><br />
            Приносим свои извинения за причинённые неудобства. В ближайшее время сервис оплаты будет работать в прежнем режиме. 
            <br /><br />
            С любовью, ваш Презент.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handletrClose}>
              Понятно
            </Button>

          </Modal.Footer>
        </Modal>       
      </Container >


    </>

  );

})

export default Cart;